import { PaperAirplaneIcon } from "@heroicons/react/24/outline";
import { useMutation } from "@tanstack/react-query";
import React, { useEffect, useState, useRef } from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

import { useFetchContext } from "../contextProviders/FetchProvider";
import { useNotificationContext } from "../contextProviders/NotificationProvider";
import Loader from "./Loader";
import { Prose } from "./Prose";

const ModuleSession = ({ data, sessionId, agent, firstCompletion = null }) => {
  const textareaRef = useRef(null);
  
  const { fetchWrapper } = useFetchContext();
  const { emitNotification } = useNotificationContext();
  const [generatedResponseData, setGeneratedResponseData] =
    useState(firstCompletion);

  const [inputPrompt, setInputPrompt] = useState("");
  const [isTyping, setIsTyping] = useState(true);
  const [charCount, setCharCount] = useState(0);

  const [copied, setCopied] = useState(false);

  const copyResponse = () => {
    navigator.clipboard
      .writeText(generatedResponseData ? generatedResponseData : data?.response)
      .then(() => {
        setCopied(true);
        setTimeout(() => {
          setCopied(false);
        }, 1500);
      });
  };

  const handleIsTyping = () => {
    return isTyping ? "█" : "";
  };

  const { mutate, isLoading: isCompletionLoading } = useMutation({
    mutationFn: ({ body, agentId }) => {
      return fetchWrapper(`agent/${agentId}/completion`, "POST", {}, body);
    },
    onError: (error) => {
      const params = {
        success: false,
        msg: "Something went wrong!",
      };
      emitNotification(params);
      console.log("Error generating completion");
    },
    onSuccess: (completionData) => {
      if (completionData?.response)
        setGeneratedResponseData(completionData.response);
    },
  });

  useEffect(() => {
    if (generatedResponseData && isTyping) {
      const textLength = generatedResponseData.length || 0;
      let count = 0;

      const timer = setInterval(() => {
        if (count < textLength) {
          setCharCount((prevCount) => prevCount + 1);
          count++;
        } else {
          setIsTyping(false);
          clearInterval(timer);
        }
      }, 10);

      return () => {
        clearInterval(timer);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [generatedResponseData]);

  useEffect(() => {
    const resize = () => {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    };
    if (textareaRef.current) {
      textareaRef.current.addEventListener("input", resize, false);
      // Ensure textarea is resized initially
      resize();
    }

    // Cleanup after component is unmounted
    return () => {
      if (textareaRef.current) {
        textareaRef.current.removeEventListener("input", resize, false);
      }
    };
  }, []);

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      handleRegenerate();
      setInputPrompt("");
      textareaRef.current.style.height = "24px";
    }
  };

  const handleRegenerate = () => {
    const body = {
      session_id: sessionId
    };

    if(inputPrompt) {
      body.input = inputPrompt;
      setInputPrompt("");
    }

    mutate({ body, agentId: agent.id });
  };

  return (
    <div className="mt-5 mb-8">
      <div className="rounded-t-md bg-[#E5E7EB] dark:bg-[#343541] p-4 flex justify-between">
        <span className="font-semibold">
          {data?.agent_display_name || agent.display_name}
        </span>
        {(generatedResponseData || data) && (
          <div className="flex">
            <button
              disabled={copied}
              onClick={() => copyResponse()}
              className="p-2 bg-gray-400 dark:bg-[#232329] rounded-md mr-1 hover:scale-105 ease-in duration-75"
            >
              {copied ? (
                <img width={15} src="/tick.png" alt="" />
              ) : (
                <img width={15} src="/SquareCopy.png" alt="" />
              )}
            </button>
            {isCompletionLoading ? (
              <div className="p-[0.3rem] bg-gray-400 dark:bg-[#232329] rounded-md  hover:scale-105 ease-in duration-75 flex items-center">
                <div className="spinner center">
                  <div className="spinner-blade"></div>
                  <div className="spinner-blade"></div>
                  <div className="spinner-blade"></div>
                  <div className="spinner-blade"></div>
                  <div className="spinner-blade"></div>
                  <div className="spinner-blade"></div>
                  <div className="spinner-blade"></div>
                  <div className="spinner-blade"></div>
                  <div className="spinner-blade"></div>
                  <div className="spinner-blade"></div>
                  <div className="spinner-blade"></div>
                  <div className="spinner-blade"></div>
                </div>
              </div>
            ) : (
              <button
                className="p-2 bg-gray-400 dark:bg-[#232329] rounded-md  hover:scale-105 ease-in duration-75"
                onClick={handleRegenerate}
              >
                <img width={15} src="/ArrowReloader.png" alt="" />
              </button>
            )}
          </div>
        )}
      </div>

      <div
        className={`${
          isTyping && generatedResponseData && "animate-pulse"
        } rounded-b-md bg-white dark:bg-black max-h-200 p-4 overflow-y-auto custom-scrollbar text-gray-400 dark:text-white`}
      >

      {isCompletionLoading ? (
      <div className="flex justify-center">
        <Loader className="h-10 w-10 m-20" />
      </div>
      ) : (
        <>
        {generatedResponseData ? (
          <Prose as="article" className="leading-3">
            <ReactMarkdown
              children={
                generatedResponseData.slice(0, charCount) + handleIsTyping()
              }
              remarkPlugins={[remarkGfm]}
              className="ml-0"
            />
          </Prose>
        ) : (
          <Prose as="article" className="leading-3">
            <ReactMarkdown
              children={data?.response}
              remarkPlugins={[remarkGfm]}
              className="ml-0"
            />
          </Prose>
        )}
        </>
      )}
      </div>

      <div className="relative flex h-full flex-1 md:flex-col mt-4">
        <div className="flex flex-col w-full py-2 flex-grow md:py-3 md:pl-4 relative border border-black/10 bg-white dark:border-zinc-900/50 dark:text-white dark:bg-zinc-700/80 rounded-md shadow-[0_0_3px_rgba(0,0,0,0.10)] dark:shadow-[0_0_15px_rgba(0,0,0,0.10)]">
          <textarea
            ref={textareaRef}
            tabIndex="0"
            name="inputPrompt"
            disabled={isCompletionLoading}
            style={{ maxHeight: "200px", overflowY: "auto" }}
            rows="1"
            placeholder=""
            onKeyDown={handleKeyDown}
            value={inputPrompt}
            onChange={(e) => setInputPrompt(e.target.value)}
            className="m-0 w-full resize-none border-0 bg-transparent p-0 pl-2 pr-7 focus:ring-0 focus-visible:ring-0 dark:bg-transparent md:pl-0 font-medium"
          ></textarea>
          <button
            type="submit"
            onClick={handleRegenerate}
            disabled={isCompletionLoading}
            className="absolute p-1 rounded-md text-gray-500 bottom-1.5 right-1 md:bottom-2.5 md:right-2 hover:bg-gray-100 dark:hover:text-gray-400 dark:hover:bg-zinc-900 disabled:hover:bg-transparent dark:disabled:hover:bg-transparent"
          >
            {!isCompletionLoading ? (
              <PaperAirplaneIcon className="h-5 w-5 ml-1 mb-1 -rotate-45" />
            ) : (
              <div className="spinner center">
                <div className="spinner-blade"></div>
                <div className="spinner-blade"></div>
                <div className="spinner-blade"></div>
                <div className="spinner-blade"></div>
                <div className="spinner-blade"></div>
                <div className="spinner-blade"></div>
                <div className="spinner-blade"></div>
                <div className="spinner-blade"></div>
                <div className="spinner-blade"></div>
                <div className="spinner-blade"></div>
                <div className="spinner-blade"></div>
                <div className="spinner-blade"></div>
              </div>
            )}
          </button>
        </div>
      </div>

    </div>
  );
};

export default ModuleSession;
