import React, { createContext, useMemo } from "react";
import { useAuth0 } from "@auth0/auth0-react";

const FetchContext = createContext(null);

const FetchProvider = ({ children }) => {
  const { user, getAccessTokenSilently, isAuthenticated } = useAuth0();

  let baseURL = "http://localhost:4000/";

  if (window.location.host === "platform.telios.io") {
    baseURL = "https://api.telios.io/";
  } else if (window.location.host === "staging.telios.io") {
    baseURL = "https://api-staging.telios.io/";
  }

  const fetchWrapper = useMemo(() => {
    const fetchInstance = async (
      url,
      method = "GET",
      options = {},
      body,
      ifFormData = false
    ) => {
      const accessToken = await getAccessTokenSilently();
      const headers = !ifFormData
        ? {
            "Content-Type": "application/json",
            ...options.headers,
          }
        : {};

      if (accessToken) {
        headers.Authorization = `Bearer ${accessToken}`;
      }

      let call = {
        method: method,
        ...options,
        headers,
      };

      if (body) {
        call.body = ifFormData ? body : JSON.stringify(body);
      }

      const response = await fetch(baseURL + url, call);

      if (!response.ok) {
        throw new Error(`Error fetching data: ${response.statusText}`);
      }

      // Handle file blob for excel download
      if(url.indexOf('/questionnaire') > -1){
        // convert data to blob
        const blob = await response.blob(); 
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement('a');
        a.href = url;
        a.download = `${options.session_title} Questionnaire.xlsx`;
        a.click();
      }
      else{
        return await response.json();
      }
    };

    return fetchInstance;
  }, [user?.email, isAuthenticated]);

  const fetcherFn = async ({
    queryKey,
    params = {},
    method = "GET",
    options,
  }) => {
    const queryString = new URLSearchParams(params).toString();
    const url = `${queryKey[0]}${queryString ? "?" + queryString : ""}`;
    const data = await fetchWrapper(url, method, options);
    return data;
  };

  const value = {
    fetchWrapper,
    fetcherFn,
  };

  return (
    <FetchContext.Provider value={value}>{children}</FetchContext.Provider>
  );
};

const useFetchContext = () => {
  const context = React.useContext(FetchContext);
  if (context === undefined) {
    throw new Error("useFetchContext must be used within a FetchProvider");
  }
  return context;
};

export { FetchProvider, useFetchContext };
