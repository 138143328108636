import { PlusIcon } from "@heroicons/react/20/solid";
import {
  TrashIcon,
  XMarkIcon,
  ChevronDoubleDownIcon,
  ChevronDoubleUpIcon,
} from "@heroicons/react/24/outline";
import { useMutation } from "@tanstack/react-query";
import clsx from "clsx";
import JSON5 from "json5";
import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useParams } from "react-router-dom";
import SyntaxHighlighter from "react-syntax-highlighter";
import { stackoverflowDark } from "react-syntax-highlighter/dist/esm/styles/hljs";

import { useFetchContext } from "../contextProviders/FetchProvider";
import { useGlobal } from "../contextProviders/GlobalProvider";
import { useNotificationContext } from "../contextProviders/NotificationProvider";
import { Button } from "./Button";
import { DeletionModal } from "./Modals";
import { Input, SearchCombobox } from "./Input";
import { motion } from "framer-motion";

import FileIcon from "./FileIcon";

const exampleFiles = [
  // {
  //   id: "6491abcddb5f02c9f24aa1f0",
  //   status: "INDEXING",
  //   name: "Informacion familias SIM Marzo 2023.pdf",
  //   lastModified: 1686266257201,
  //   lastModifiedDate: "Thu Jun 08 2023 20:17:37 GMT-0300",
  //   size: 56857,
  //   type: "application/pdf",
  // },
  // {
  //   id: "649056ffecc8eda88270a53f",
  //   status: "DONE",
  //   name: "CheatSheet_1.6.4.dmg",
  //   lastModified: 1684758311818,
  //   lastModifiedDate:
  //     "Mon May 22 2023 09:25:11 GMT-0300 (Uruguay Standard Time)",
  //   size: 2757692,
  //   type: "",
  // },
  // {
  //   // id: "649056ffecc8eda88270a53d",
  //   id: "64932cdcf7c29ce0ced29254",
  //   status: "DONE",
  //   name: "IC2 Developer - Semester feedback report - Self-assessment.docx",
  //   lastModified: 1685987689801,
  //   lastModifiedDate:
  //     "Mon Jun 05 2023 14:54:49 GMT-0300 (Uruguay Standard Time)",
  //   size: 28072,
  //   type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  // },
];

const formatFileSize = (fileSize) => {
  if (fileSize < 1024) {
    return fileSize + " B";
  } else if (fileSize < 1024 * 1024) {
    return (fileSize / 1024).toFixed(0) + " KB";
  } else {
    return (fileSize / (1024 * 1024)).toFixed(0) + " MB";
  }
};

const DotCircle = ({ className }) => (
  <svg
    className={className}
    width="6"
    height="5"
    viewBox="0 0 6 5"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M2.9375 4.63068C2.53646 4.63068 2.17022 4.53291 1.83878 4.33736C1.50734 4.13849 1.24219 3.87334 1.04332 3.5419C0.847775 3.21046 0.75 2.84422 0.75 2.44318C0.75 2.03883 0.847775 1.67259 1.04332 1.34446C1.24219 1.01302 1.50734 0.749526 1.83878 0.553977C2.17022 0.355113 2.53646 0.255682 2.9375 0.255682C3.34186 0.255682 3.7081 0.355113 4.03622 0.553977C4.36766 0.749526 4.63116 1.01302 4.8267 1.34446C5.02557 1.67259 5.125 2.03883 5.125 2.44318C5.125 2.84422 5.02557 3.21046 4.8267 3.5419C4.63116 3.87334 4.36766 4.13849 4.03622 4.33736C3.7081 4.53291 3.34186 4.63068 2.9375 4.63068Z" />
  </svg>
);

const FilesUploaded = ({ setSelectedFile, setIsDeletionModalOpen }) => {
  const handleDelete = (file) => {
    setSelectedFile(file);
    setIsDeletionModalOpen(true);
  };

  return (
    <ul className="text-text-zinc-500 dark:text-cyan-50 mx-4 my-6">
      {exampleFiles.map((file) => (
        <li key={file?.id} className="grid grid-cols-12 my-2 items-center">
          <div className="flex justify-between my-2 items-center col-span-7">
            <div className="flex items-center w-4/5">
              <FileIcon fileName={file.name} />
              <div className="text-xs truncate w-9/12" title={file.name}>
                {file.name}
              </div>
            </div>
          </div>
          <div className="flex flex-row items-center col-span-4">
            <DotCircle className="mx-2 fill-zinc-500 dark:fill-white" />
            <div className="text-xs text-[#AEAEAE]">
              {formatFileSize(file.size)}
            </div>
          </div>
          {/* <UploadProgressBar /> */}
          <XMarkIcon
            className="h-6 w-6 p-1 dark:text-cyan-50 rounded cursor-pointer col-span-1"
            onClick={() => handleDelete(file)}
          />
        </li>
      ))}
    </ul>
  );
};

function FileUpload() {
  const { fetchWrapper } = useFetchContext();
  const { emitNotification } = useNotificationContext();
  const { drawer, setDrawer } = useGlobal();
  const { id: sessionId, agentId: agentIdParams } = useParams();

  const [isDeletionModalOpen, setIsDeletionModalOpen] = useState(false);
  const [isBulkDeletionModal, setIsBulkDeletionModal] = useState(false);
  const [isBulkDeletionConfirm, setIsBulkDeletionConfirm] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadSucceed, setUploadSucceed] = useState(false);
  const [generatedFilesData, setGeneratedFilesData] = useState(null);

  useEffect(() => {
    if (drawer?.jsonData) {
      try {
        setGeneratedFilesData({
          data:
            typeof drawer.jsonData == "object"
              ? JSON5.stringify(drawer.jsonData, null, "\t")
              : JSON5.stringify(JSON5.parse(drawer.jsonData), null, "\t"),
          type: "json",
        });
      } catch (error) {
        setGeneratedFilesData({
          data: drawer.jsonData,
          type: "text",
        });
      }
    }
  }, [drawer?.jsonData]);

  const { mutate, isLoading: isFilesUploading } = useMutation({
    mutationFn: (formData) => {
      return fetchWrapper("upload/files", "POST", {}, formData, true);
    },
    onError: (error) => {
      const params = {
        success: false,
        msg: "Something went wrong!",
      };
      emitNotification(params);
    },
    onSuccess: ({ data }) => {
      if (data) {
        try {
          setGeneratedFilesData({
            data: JSON5.stringify(data, null, "\t"),
            type: "json",
          });
        } catch (error) {
          setGeneratedFilesData({
            data,
            type: "text",
          });
        }
      } else {
        setGeneratedFilesData({
          data: "No data found",
          type: "text",
        });
      }
      setUploadSucceed(true);
      const params = {
        success: true,
        msg: `File${
          selectedFiles.length > 1 ? "s" : ""
        } uploaded successfully!`,
      };
      emitNotification(params);

      setDrawer({
        ...drawer,
        refetchSessionData: true,
      });
    },
  });

  const { mutate: bulkDelete, isLoading: isBulkDeleteLoading } = useMutation({
    mutationFn: () => {
      return fetchWrapper("file/6491abcddb5f02c9f24aa1f0", "DELETE");
    },
    // onError: (error) => {
    //   const params = {
    //     success: false,
    //     msg: "Something went wrong!",
    //   };
    //   emitNotification(params);
    // },
    // onSuccess: ({ data }) => {
    //   // queryClient.invalidateQueries({
    //   //   queryKey: [...invalidationArray],
    //   // });
    //   const params = {
    //     success: true,
    //     msg: `6491abcddb5f02c9f24aa1f0 successfully deleted!`,
    //   };
    //   emitNotification(params);
    // },
  });

  useEffect(() => {
    if (isBulkDeletionConfirm) {
      bulkDelete();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isBulkDeletionConfirm]);

  const handleDrop = (acceptedFiles) => {
    setUploadSucceed(false);
    setSelectedFiles([
      ...selectedFiles,
      ...acceptedFiles.map((file) => ({
        file,
        showForm: file.type === "application/pdf" || file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ? true : false,
        fileName: file.name,
        fileSize: file.size,
      })),
    ]);
  };

  const handleUpload = async () => {
    try {
      const formData = new FormData();
      const filters = {};

      selectedFiles.forEach((file, index) => {
        formData.append("files", file.file);
        filters[file.file.name] = file.filter;
      });
      formData.append("agent_id", agentIdParams || drawer.agentId);
      if (sessionId) formData.append("session_id", sessionId);
      formData.append("filters", JSON.stringify(filters));

      mutate(formData);
    } catch (error) {
      const params = {
        success: false,
        msg: "Something went wrong!",
      };
      emitNotification(params);
    }
  };

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    open,
    isDragReject,
    isDragAccept,
  } = useDropzone({
    onDrop: handleDrop,
    multiple: true,
    accept: {
      "image/jpeg": [".jpeg"],
      "image/png": [".png"],
      "image/tiff": [".tif", ".tiff"],
      "application/pdf": [".pdf"],
      "text/csv": [".csv"],
      "application/vnd.ms-powerpoint": [".ppt"],
      "application/vnd.openxmlformats-officedocument.presentationml.presentation":
        [".pptx"],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        [".docx"],
      "application/msword": [".doc"],
      "application/vnd.ms-excel": [".xls"],
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [
        ".xlsx",
      ],
    },
  });

  const handleDelete = (file) => {
    setSelectedFiles((files) =>
      files.filter((f) => f.fileName !== file.fileName)
    );
    setUploadSucceed(false);
  };

  const handleBulkDelete = () => {
    setIsBulkDeletionModal(true);
  };

  const handleDeleteJSON = () => {
    setGeneratedFilesData(null);
    setDrawer({
      ...drawer,
      refetchSessionData: true,
      jsonData: null,
      filesName: null,
    });
  };

  const [isDeletionModalOpenJson, setIsDeletionModalOpenJson] = useState(false);

  return (
    <div className="max-w-3xl">
      <div className="flex justify-between text-lg ml-4 my-5">
        <h2 className="text-zinc-900 dark:text-zinc-50 font-medium">
          Upload your Files
        </h2>
        <div className="flex justify-around">
          <PlusIcon
            className="h-8 w-8 p-1 bg-[#E5E7EB] rounded cursor-pointer mr-4 text-zinc-900 dark:bg-[#29292D] dark:text-white"
            onClick={open}
          />
          {exampleFiles.length > 0 && (
            <TrashIcon
              className="h-8 w-8 p-1 bg-[#E5E7EB] rounded cursor-pointer text-zinc-900 dark:bg-[#29292D] dark:text-white"
              onClick={handleBulkDelete}
            />
          )}
        </div>
      </div>
      <div
        {...getRootProps()}
        // className={`dropzone ${isDragActive ? "active" : ""} ${
        //   selectedFiles.length > 0 ? "inline" : "hidden"
        // }`}
        className={clsx(
          selectedFiles.length > 0 && "hidden",
          `dropzone ${isDragActive ? "active" : ""}`
        )}
      >
        <input {...getInputProps()} />
        {isDragReject && (
          <div className="flex flex-row justify-center rounded-md bg-red-100 border border-red-200 dark:border-red-300 dark:bg-red-200 px-6 py-2 m-4">
            <div className="space-y-1 text-sm">
              <p className="text-red-700 font-semibold text-base">
                Some files will be rejected.
              </p>
            </div>
          </div>
        )}
        {exampleFiles.length === 0 && (
          <div
            className={clsx([
              "text-zinc-500 dark:text-cyan-50",
              isDragAccept ? "bg-gray-200 dark:bg-white/10" : "",
              "border-dashed border border-[#9747FF] rounded p-12 m-4 ",
              "text-center h-64 flex justify-center",
            ])}
          >
            <div className="font-medium self-center">
              Drag & Drop your files here, or{" "}
              <button className="dark:bg-[#444654] bg-[#E5E7EB] text-[#343541] dark:text-cyan-50 rounded-md px-2 py-1">
                Choose your files
              </button>
            </div>
          </div>
        )}
      </div>
      {selectedFiles.length > 0 && (
        <div>
          <ul className="text-text-zinc-500 dark:text-cyan-50 mx-4 my-6">
            {selectedFiles.map((file) => (
              <div key={file?.fileName}>
                <li className="grid grid-cols-12 my-2 items-center">
                  <div className="flex justify-between my-2 items-center col-span-7">
                    <div className="flex items-center w-4/5">
                      {file.showForm ? (
                        <ChevronDoubleUpIcon
                          className="h-6 w-6 p-1 dark:text-cyan-50 rounded cursor-pointer col-span-1 justify-self-center hover:rotate-180 hover:delay-300"
                          onClick={() => {
                            const updatedFiles = selectedFiles.map((f) => {
                              if (f.fileName === file.fileName) {
                                return {
                                  ...f,
                                  showForm: false
                                };
                              }
                              return f;
                            });
                            setSelectedFiles(updatedFiles);
                          }}
                        />
                      ) : (
                        <ChevronDoubleDownIcon
                          className="h-6 w-6 p-1 dark:text-cyan-50 rounded cursor-pointer col-span-1 justify-self-center"
                          onClick={() => {
                            const updatedFiles = selectedFiles.map((f) => {
                              if (f.fileName === file.fileName) {
                                return {
                                  ...f,
                                  showForm: true
                                };
                              }
                              return f;
                            });
                            setSelectedFiles(updatedFiles);
                          }}
                        />
                      )}

                      <FileIcon fileName={file.fileName} />
                      <div
                        className="text-xs truncate w-9/12"
                        title={file.fileName}
                      >
                        {file.fileName}
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row items-center col-span-2">
                    <DotCircle className="mx-2 fill-zinc-500 dark:fill-white" />
                    <div className="text-xs text-[#AEAEAE]">
                      {formatFileSize(file.fileSize)}
                    </div>
                  </div>

                  <div className="col-span-2">
                    {isFilesUploading && (
                      <div className="spinner center">
                        <div className="spinner-blade"></div>
                        <div className="spinner-blade"></div>
                        <div className="spinner-blade"></div>
                        <div className="spinner-blade"></div>
                        <div className="spinner-blade"></div>
                        <div className="spinner-blade"></div>
                        <div className="spinner-blade"></div>
                        <div className="spinner-blade"></div>
                        <div className="spinner-blade"></div>
                        <div className="spinner-blade"></div>
                        <div className="spinner-blade"></div>
                        <div className="spinner-blade"></div>
                      </div>
                    )}
                  </div>
                  <XMarkIcon
                    className="h-6 w-6 p-1 dark:text-cyan-50 rounded cursor-pointer col-span-1 justify-self-center"
                    onClick={() => handleDelete(file)}
                  />
                </li>
                {file.showForm && (
                  <motion.div
                    animate={{
                      x: 0,
                      y: 25,
                      scale: 1,
                      rotate: 0,
                    }}
                  >
                    <div className="-mt-10 ml-8">
                      <div className="w-6/12">
                        <div className="flex">
                          <Input
                            id={`fileFilter-${file.fileName}`}
                            name={`fileFilter-${file.fileName}`}
                            label={
                              file.file.type === "application/pdf"
                                ? "Select Pages"
                                : "Select Worksheets"
                            }
                            type="text"
                            required
                            placeholder={
                              file.file.type === "application/pdf"
                                ? "e.g. 1-5,8,11-13"
                                : "e.g. Worksheet1, Worksheet2"
                            }
                            value={file.filter}
                            className="mr-2 w-full"
                            onChange={(event) => {
                              const updatedFiles = selectedFiles.map((f) => {
                                if (f.fileName === file.fileName) {
                                  return {
                                    ...f,
                                    filter: event.target.value
                                  };
                                }
                                return f;
                              });
                              setSelectedFiles(updatedFiles);
                            }}
                          />
                        </div>
                        <div className="flex justify-end mt-4 text-xs">
                          <button
                            onClick={() => {
                              const updatedFiles = selectedFiles.map((f) => {
                                if (f.fileName === file.fileName) {
                                  return {
                                    ...f,
                                    filter: '',
                                    showForm: false
                                  };
                                }
                                return f;
                              });
                              setSelectedFiles(updatedFiles);
                            }}
                            className="py-1 px-2 rounded-md"
                          >
                            Cancel
                          </button>
                          <button onClick={() => {
                            const updatedFiles = selectedFiles.map((f) => {
                              if (f.fileName === file.fileName) {
                                return {
                                  ...f,
                                  showForm: false
                                };
                              }
                              return f;
                            });
                            setSelectedFiles(updatedFiles);
                          }}
                          className="bg-purple-600 py-1 px-2 rounded-md mx-2">
                            Continue
                          </button>
                        </div>
                      </div>
                    </div>
                  </motion.div>
                )}
              </div>
            ))}
          </ul>
        </div>
      )}
      <FilesUploaded
        setSelectedFile={setSelectedFile}
        setIsDeletionModalOpen={setIsDeletionModalOpen}
      />
      {selectedFiles.length > 0 && !uploadSucceed && (
        <div className="flex justify-end">
          <Button
            className="rounded-md mr-2"
            onClick={() => setSelectedFiles([])}
            variant="secondary"
          >
            Cancel
          </Button>
          {!isFilesUploading ? (
            <Button
              className="rounded-md mx-2"
              onClick={handleUpload}
              variant="filled"
            >
              Save
            </Button>
          ) : (
            <Button
              className="rounded-md mx-2 opacity-50 cursor-wait"
              variant="filled"
            >
              Save
            </Button>
          )}
        </div>
      )}
      <DeletionModal
        setIsOpen={setIsDeletionModalOpenJson}
        isOpen={isDeletionModalOpenJson}
        deleteApiUrl={`session/${sessionId}/index?agent_id=${
          agentIdParams || drawer.agentId
        }`}
        entityLabel={"JSON"}
        goBack={false}
        callBack={true}
        callBackAction={handleDeleteJSON}
      >
        <p className="text-sm text-zinc-600 dark:text-zinc-400">
          Are you sure you want to delete this JSON? It will be lost forever.
        </p>
      </DeletionModal>
      <div className="flex flex-wrap justify-between mx-5 mt-4">
        {generatedFilesData &&
        drawer?.filesName &&
        drawer.filesName.length > 0 ? (
          <div className="flex flex-wrap" style={{maxWidth: "100%"}}>
            {drawer.filesName.map((fileName, index) => (
              <div
                key={index}
                className="flex items-center text-text-zinc-500 dark:text-cyan-50"
              >
                <FileIcon fileName={fileName} />
                <div
                  className="text-xs truncate w-9/12 max-w-xs"
                  title={fileName}
                >
                  {fileName}
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div />
        )}
      </div>
      <>
      {generatedFilesData && generatedFilesData.data != "[]" ? (
          <div className="text-right my-auto">
            <button
              onClick={() => setIsDeletionModalOpenJson(true)}
              className="dark:text-zinc-50  my-auto"
            >
              <TrashIcon width={20} height={20} />
            </button>
          </div>
        ) : null}
      </>
      {generatedFilesData && (
        <div className="mt-3 mx-4 text-sm dark:text-zinc-400 text-zinc-500">
          {generatedFilesData.type === "json" ? (
            <SyntaxHighlighter
              language="javascript"
              style={stackoverflowDark}
              className="rounded"
              showLineNumbers
            >
              {generatedFilesData.data}
            </SyntaxHighlighter>
          ) : (
            <SyntaxHighlighter
              language="javascript"
              style={stackoverflowDark}
              className="rounded"
              wrapLongLines
            >
              {generatedFilesData.data}
            </SyntaxHighlighter>
          )}
        </div>
      )}
      <DeletionModal
        setIsOpen={setIsDeletionModalOpen}
        isOpen={isDeletionModalOpen}
        deleteApiUrl={`file/${selectedFile?.id}`}
        entityLabel={selectedFile?.name}
        goBack={false}
      >
        <p className="text-sm text-zinc-600 dark:text-zinc-400">
          Are you sure you want to delete this file? It will be lost forever.
        </p>
      </DeletionModal>
      <DeletionModal
        setIsOpen={setIsBulkDeletionModal}
        isOpen={isBulkDeletionModal}
        deleteApiUrl={null}
        entityLabel={exampleFiles.map((file) => file.name).join(" - ")}
        setIsBulkDeletionConfirm={setIsBulkDeletionConfirm}
        isDeleting={isBulkDeleteLoading}
        goBack={false}
      >
        <p className="text-sm text-zinc-600 dark:text-zinc-400">
          Are you sure you want to delete all these files? They will be lost
          forever.
        </p>
      </DeletionModal>
    </div>
  );
}

export default FileUpload;
