import { XMarkIcon } from "@heroicons/react/24/outline";

import { useGlobal } from "../contextProviders/GlobalProvider";
import { CONSTANTS } from "../utils/helpers";
import AgentDrawer from "./AgentDrawer";
import { Member } from "./Member";

const { DRAWER_TYPE } = CONSTANTS;

const renderComponent = (type, agent, org_id = "") => {
  if (type === DRAWER_TYPE.AGENT) {
    return <AgentDrawer agent={agent} />;
  } else if (
    type === DRAWER_TYPE.ADD_MEMBER ||
    type === DRAWER_TYPE.EDIT_MEMBER
  ) {
    return <Member orgId={org_id} />;
  } else {
    return null;
  }
};

const Drawer = ({ children, agent }) => {
  const { drawer, setDrawer } = useGlobal();

  if (!agent) return null;

  return (
    <main
      className={
        " fixed overflow-hidden z-50 bg-gray-900 bg-opacity-50 inset-0 transform ease-in-out backdrop-blur-xs" +
        (drawer.isOpen
          ? " transition-opacity opacity-100 duration-500 translate-x-0  "
          : " transition-all delay-500 opacity-0 translate-x-full  ")
      }
    >
      <section
        className={
          ` w-screen ${
            drawer?.type === DRAWER_TYPE.AGENT ? "max-w-5xl" : "max-w-3xl"
          } right-0 absolute bg-white dark:bg-[#18181A] border-cyan-50 h-full shadow-xl delay-400 duration-500 ease-in-out transition-all transform  ` +
          (drawer.isOpen ? " translate-x-0 " : " translate-x-full ")
        }
      >
        <article
          className={`relative w-screen ${
            drawer?.type === DRAWER_TYPE.AGENT ? "max-w-5xl" : "max-w-3xl"
          } pb-10 flex flex-col overflow-y-scroll h-full`}
        >
          <header className="py-3 px-5 font-medium text-lg flex justify-between">
            <div className="text-zinc-900 dark:text-cyan-50 text-lg">
              {drawer.title}
            </div>
            <XMarkIcon
              className="h-8 w-8 p-1 dark:text-cyan-50 rounded cursor-pointer"
              onClick={() =>
                setDrawer({
                  ...drawer,
                  isOpen: false,
                  type: "",
                  agentId: "",
                  jsonData: null,
                  filesName: null,
                })
              }
            />
          </header>
          <header
            className={`px-1 py-4 ${
              drawer?.type === DRAWER_TYPE.ADD_MEMBER ||
              drawer?.type === DRAWER_TYPE.EDIT_MEMBER
                ? ""
                : "flex"
            } justify-around border-[#6B7280] border-t-2 text-sm font-normal`}
          >
            {renderComponent(drawer.type, agent, drawer?.org_id)}
          </header>
        </article>
      </section>
      <section
        className=" w-screen h-full cursor-pointer "
        onClick={() => {
          setDrawer({
            ...drawer,
            isOpen: false,
            type: "",
            agentId: "",
            jsonData: null,
            filesName: null,
          });
        }}
      ></section>
    </main>
  );
};

export default Drawer;
