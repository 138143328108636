import { PaperAirplaneIcon } from "@heroicons/react/24/outline";
import { useMutation } from "@tanstack/react-query";
import React, { useEffect, useState, useRef } from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

import { useFetchContext } from "../contextProviders/FetchProvider";
import { useNotificationContext } from "../contextProviders/NotificationProvider";
import Loader from "./Loader";
import { Prose } from "./Prose";
import { Button } from "./Button";

const QuestionnaireModule = ({ sessionId, hasSessionData, onFileUpload, sessionDataTemplates }) => {
  // const textareaRef = useRef(null);
  
  const { fetchWrapper } = useFetchContext();
  const { emitNotification } = useNotificationContext();
  // const [generatedResponseData, setGeneratedResponseData] =
  //   useState(firstCompletion);

  // const [inputPrompt, setInputPrompt] = useState("");
  // const [isTyping, setIsTyping] = useState(true);
  // const [charCount, setCharCount] = useState(0);

  // const [copied, setCopied] = useState(false);

  // const copyResponse = () => {
  //   navigator.clipboard
  //     .writeText(generatedResponseData ? generatedResponseData : data?.response)
  //     .then(() => {
  //       setCopied(true);
  //       setTimeout(() => {
  //         setCopied(false);
  //       }, 1500);
  //     });
  // };

  // const handleIsTyping = () => {
  //   return isTyping ? "█" : "";
  // };

  const { mutate } = useMutation({
    mutationFn: () => {
      // return fetchWrapper(`agent/${agentId}/completion`, "POST", {}, body);
      return fetchWrapper(`session/${sessionId}/questionnaire`, "GET", {session_title: sessionDataTemplates[0].session_title});
    },
    onError: (error) => {
      const params = {
        success: false,
        msg: "Error exporting questionnaire",
      };
      emitNotification(params);
      console.log("Error exporting questionnaire");
    },
    onSuccess: (completionData) => {
      // if (completionData?.response)
      //   setGeneratedResponseData(completionData.response);
    },
  });

  // useEffect(() => {
  //   if (generatedResponseData && isTyping) {
  //     const textLength = generatedResponseData.length || 0;
  //     let count = 0;

  //     const timer = setInterval(() => {
  //       if (count < textLength) {
  //         setCharCount((prevCount) => prevCount + 1);
  //         count++;
  //       } else {
  //         setIsTyping(false);
  //         clearInterval(timer);
  //       }
  //     }, 10);

  //     return () => {
  //       clearInterval(timer);
  //     };
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [generatedResponseData]);

  // useEffect(() => {
  //   const resize = () => {
  //     textareaRef.current.style.height = "auto";
  //     textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
  //   };
  //   if (textareaRef.current) {
  //     textareaRef.current.addEventListener("input", resize, false);
  //     // Ensure textarea is resized initially
  //     resize();
  //   }

  //   // Cleanup after component is unmounted
  //   return () => {
  //     if (textareaRef.current) {
  //       textareaRef.current.removeEventListener("input", resize, false);
  //     }
  //   };
  // }, []);

  // const handleKeyDown = (event) => {
  //   if (event.key === "Enter" && !event.shiftKey) {
  //     event.preventDefault();
  //     handleRegenerate();
  //     setInputPrompt("");
  //     textareaRef.current.style.height = "24px";
  //   }
  // };

  const handleExport = () => {
    mutate();
  };

  return (
    <div className="rounded-b-md bg-white dark:bg-black h-32 p-4 overflow-y-auto custom-scrollbar text-gray-400 dark:text-white border-[#E5E7EB]">
      <div className="mt-8 sm:mt-8 sm:ml-2 sm:flex-none">
        {hasSessionData ? (
          <Button
            type="button"
            onClick={() => handleExport()}
            plus="left"
          >
            Export Questionnaire
          </Button>
        ) : (
          <Button
            type="button"
            onClick={() => onFileUpload()}
            plus="left"
          >
            Upload a file
          </Button>
        )}
        </div>
    </div>
  );
};

export default QuestionnaireModule;
